<template>
  <div>
    <div class="mt-4 form-inline">
      <b-form-group
        label="# of Tickets"
        label-for="input-num-tickets"
        :invalid-feedback="veeErrors.first('input-num-tickets')"
        label-class="sr-only"
        class="mr-2"
      >
        <b-form-input
          name="input-num-tickets"
          v-model="numTickets"
          type="number"
          v-validate="{ required: true, min_value: 1 }"
          :state="validateState('input-num-tickets')"
          aria-describedby="input-num-tickets-feedback"
          placeholder="# of Tickets"
          class="input-sm"
          data-vv-as="number of tickets"
        ></b-form-input>
      </b-form-group>
      <div>
        <label for="input-priceCents" class="sr-only">Price</label>
        <b-input-group prepend="$" append=".00" class="mr-2">
          <b-form-input
            name="input-priceCents"
            v-model="priceCents"
            type="number"
            v-validate="{ required: true, min_value: 1 }"
            :state="validateState('input-priceCents')"
            placeholder="Price"
            class="input-sm"
            data-vv-as="priceCents"
          ></b-form-input>
        </b-input-group>
        <b-form-invalid-feedback id="input-priceCents-feedback" :state="validateState('input-priceCents')">{{
          veeErrors.first('input-priceCents')
        }}</b-form-invalid-feedback>
      </div>

      <b-form-group
        label="Series"
        label-for="input-series"
        description="Series"
        :invalid-feedback="veeErrors.first('input-series')"
        label-class="sr-only"
        class="mr-2"
        ><b-form-select
          name="select-event"
          v-model="series"
          :options="seriesOptions"
          v-validate="{ required: true, included: ['A', 'B', 'C', 'D', 'E'] }"
          :state="validateState('input-series')"
          data-vv-as="raffle"
          style="min-width: 5rem"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        label="Limit"
        label-for="input-limit"
        description="This is optional"
        :invalid-feedback="veeErrors.first('input-limit')"
        label-class="sr-only"
        class="mr-2"
      >
        <b-form-input
          name="input-limit"
          v-model="limit"
          type="number"
          v-validate="{}"
          :state="validateState('input-limit')"
          aria-describedby="input-limit-feedback"
          placeholder="Limit"
          class="input-sm"
          data-vv-as="limit"
        ></b-form-input>
      </b-form-group>

      <div>
        <b-button v-if="isEdit" @click="updateTicketPackage" variant="outline-primary" size="md">
          <span>Edit</span>
        </b-button>
      </div>
      <div>
        <b-button v-if="!isEdit" variant="outline-primary" size="md" @click="addTicketPackages">
          <span>Add</span>
        </b-button>
      </div>
    </div>
    <b-table
      show-empty
      id="table"
      class="ticket-packages-table mt-4"
      striped
      :fields="visibleFields"
      :items="tickets"
      foot-clone
    >
      <template v-slot:cell(priceCents)="row">
        {{ formatCurrency(row.item.priceCents) }}
      </template>
      <template v-slot:cell(total)="row">
        {{ row.item.limit ? formatCurrency(row.item.limit * row.item.priceCents) : '-' }}
      </template>
      <template v-slot:cell(availableTickets)="row">
        {{ row.item.limit ? row.item.availableTickets : '-' }}
      </template>
      <template v-slot:cell(edit)="row">
        <span @click="editTicketPackage(row.item)" class="table-actions"
          ><i class="fa-solid fa-pen-to-square mr-3"></i
        ></span>
        <span @click="deleteTicketPackage(row.item)" class="table-actions"><i class="fa-solid fa-trash-can"></i></span>
      </template>
      <template v-slot:foot(numTickets)>
        <span></span>
      </template>
      <template v-slot:foot(priceCents)>
        <span></span>
      </template>
      <template v-slot:foot(series)>
        <span></span>
      </template>
      <template v-slot:foot(limit)>
        <span></span>
      </template>
      <template v-slot:foot(total)>
        <span>{{ formatCurrency(totalValue) }}</span>
      </template>

      <template v-slot:foot(edit)>
        <span></span>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  computed: {
    visibleFields() {
      return this.table.fields.filter((field) => field.visible);
    }
  },
  data() {
    return {
      table: {
        fields: [
          {
            key: 'numTickets',
            label: '# of Tickets',
            visible: true
          },
          {
            key: 'priceCents',
            label: 'Price',
            visible: true
          },
          {
            key: 'series',
            label: 'Series',
            visible: true
          },
          {
            key: 'limit',
            label: 'Limit',
            visible: true
          },
          {
            key: 'total',
            label: '$ Total',
            visible: true
          },
          {
            key: 'edit',
            label: 'Edit/Delete',
            visible: true
          }
        ]
      },
      seriesOptions: [
        { text: 'A', value: 'A', disabled: false },
        { text: 'B', value: 'B', disabled: false },
        { text: 'C', value: 'C', disabled: false },
        { text: 'D', value: 'D', disabled: false },
        { text: 'E', value: 'E', disabled: false }
      ],
      tickets: [],
      newTicketPackages: {
        numTickets: '',
        priceCents: '',
        series: '',
        limit: '',
        id: ''
      },
      numTickets: null,
      priceCents: null,
      series: null,
      limit: null,
      id: null,
      baseId: 0,
      isEdit: false,
      totalValue: ''
    };
  },
  watch: {
    tickets() {
      this.updateTotalValue();
    }
  },
  methods: {
    async addTicketPackages() {
      this.seriesOptions.forEach((option) => {
        if (option.value === this.series) {
          option.disabled = true;
        }
      });
      const valid = await this.$validator.validateAll();
      const validSeries = this.validateSeries();
      if (!valid || !validSeries) {
        return;
      }
      this.isEdit = false;
      this.baseId++;
      this.newTicketPackages = {
        numTickets: this.numTickets,
        priceCents: this.priceCents,
        series: this.series,
        limit: this.limit | 0,
        id: this.baseId
      };
      this.tickets.push(this.newTicketPackages);
      this.$emit('add-tickets', this.tickets);
      this.resetForm();
    },
    editTicketPackage(item) {
      this.id = item.id;
      this.isEdit = true;
      this.numTickets = item.numTickets;
      this.priceCents = parseInt(item.priceCents);
      this.series = item.series;
      this.limit = item.limit | 0;
    },
    updateTicketPackage() {
      this.seriesOptions.forEach((option) => {
        if (option.value === this.series) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
      this.tickets.forEach((ticket) => {
        if (ticket.id === this.id) {
          ticket.numTickets = this.numTickets;
          ticket.priceCents = this.priceCents;
          ticket.series = this.series;
          ticket.limit = this.limit | 0;
        }
      });
      this.validateSeries();
      this.$validator.validateAll();
      this.resetForm();
      this.isEdit = false;
    },
    deleteTicketPackage(item) {
      this.seriesOptions.forEach((option) => {
        if (option.value === this.series) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
      this.tickets.splice(this.tickets.indexOf(item), 1);
      this.$emit('add-tickets', this.tickets);
      this.resetForm();
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    updateTotalValue() {
      this.totalValue = 0.0;
      this.tickets.forEach((ticket) => {
        this.totalValue += ticket.priceCents * ticket.limit;
      });
    },
    resetForm() {
      this.numTickets = null;
      this.priceCents = null;
      this.series = null;
      this.limit = null;
      this.isEdit = false;
      this.updateTotalValue();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    validateSeries() {
      let filteredTPs = [];

      if (this.isEdit === true) {
        const excludeCurrentTP = this.tickets.filter((tp) => tp.series !== this.series);
        filteredTPs = excludeCurrentTP.filter((tp) => tp.series === this.series);
      } else {
        filteredTPs = this.tickets.filter((tp) => tp.series === this.series);
      }

      if (filteredTPs.length > 0) {
        this.veeFields['input-series']['dirty'] = true;
        this.veeFields['input-series']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-series',
          msg: 'The series name must be unique'
        });
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.form-group,
.input-sm {
  max-width: 138px;
}

.form-inline {
  align-items: self-start;
}

.table-actions {
  cursor: pointer;
}
</style>
