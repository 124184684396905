<template>
  <div>
    <b-form-group label-for="input-category" :invalid-feedback="veeErrors.first('input-category')">
      <template slot="label">Type of Raffle <span class="text-danger">*</span></template>
      <b-form-select
        name="input-category"
        :state="validateState('input-category')"
        v-bind:value="value"
        v-on:input="onInput"
        aria-describedby="input-category-feedback"
        :options="options"
        v-validate="{ required: true }"
        data-vv-as="Category"
      />
    </b-form-group>
  </div>
</template>
<script>
import CategoryService from '@/lib/category-service';

const name = 'input-category';

export default {
  props: ['value'],
  data() {
    return {
      options: []
    };
  },
  async mounted() {
    const catagories = await CategoryService.listCategories();
    const filterValues = ['Sweepstakes', 'Promo Raffle'];
    this.options = catagories.filter((category) => !filterValues.includes(category));
  },
  methods: {
    validateState() {
      if (this.veeFields[name] && (this.veeFields[name].dirty || this.veeFields[name].validated)) {
        return !this.veeErrors.has(name);
      }

      return null;
    },
    isValid() {
      return this.veeFields[name]['invalid'] === false;
    },
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
