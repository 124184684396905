<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <b-form-group label-for="input-start-date" :invalid-feedback="veeErrors.first('input-start-date')">
          <template slot="label">Ticket Sales Start</template>
          <b-form-datepicker
            name="input-start-date"
            v-model="startDate"
            :min="minStartDate"
            :state="validateState('input-start-date')"
            aria-describedby="input-start-date-feedback"
            data-vv-as="Ticket Sales Start Date"
            @input="validateDates"
          />
        </b-form-group>

        <b-form-group label-for="input-start-time" :invalid-feedback="veeErrors.first('input-start-time')">
          <b-form-timepicker
            name="input-start-time"
            show-seconds
            v-model="startTime"
            v-validate="{
              required: true
            }"
            :min="minStartDate"
            :state="validateState('input-start-time')"
            aria-describedby="input-start-time-feedback"
            data-vv-as="Ticket Sales Start Time"
            @input="validateDates"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="input-end-date" :invalid-feedback="veeErrors.first('input-end-date')">
          <template slot="label">Ticket Sales End <span class="text-danger">*</span></template>
          <b-form-datepicker
            name="input-end-date"
            v-model="endDate"
            v-validate="'required'"
            :state="validateState('input-end-date')"
            aria-describedby="input-end-date-feedback"
            data-vv-as="Ticket Sales End Date"
            :min="minEndDate"
            @input="validateDates"
          />
        </b-form-group>

        <b-form-group label-for="input-end-time" :invalid-feedback="veeErrors.first('input-end-time')">
          <b-form-timepicker
            name="input-end-time"
            show-seconds
            v-model="endTime"
            v-validate="'required'"
            :state="validateState('input-end-time')"
            aria-describedby="input-end-time-feedback"
            data-vv-as="Ticket Sales End Time"
            :min="minEndDate"
            @input="validateDates"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="input-draw-date" :invalid-feedback="veeErrors.first('input-draw-date')">
          <template slot="label">Draw Date <span class="text-danger">*</span></template>
          <b-form-datepicker
            name="input-draw-date"
            :state="validateState('input-draw-date')"
            v-model="drawDate"
            aria-describedby="input-draw-date-feedback"
            data-vv-as="Draw Date"
            v-validate="'required'"
            :min="minDrawDate"
            @input="validateDates"
          />
        </b-form-group>
        <b-form-group
          label-for="input-draw-time"
          :invalid-feedback="veeErrors.first('input-draw-time')"
          label-class="mb-0"
        >
          <b-form-timepicker
            name="input-draw-time"
            v-model="drawTime"
            v-validate="'required'"
            :state="validateState('input-draw-time')"
            aria-describedby="input-draw-time-feedback"
            data-vv-as="Draw Time"
            show-seconds
            :min="minDrawDate"
            @input="validateDates"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getTimezone } from '@rafflebox-technologies-inc/rafflebox-lib';

export default {
  props: ['value', 'province'],
  data() {
    return {
      startDate: null,
      startTime: '06:00:00',
      startDateTime: null,
      endDate: null,
      endTime: '23:59:59',
      endDateTime: null,
      drawDate: null,
      drawTime: '23:59:59',
      drawDateTime: null,
      drawLocation: null,
      minDrawDate: new Date(),
      minEndDate: new Date(),
      minStartDate: new Date(),
      frequency: 'once'
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }

      return null;
    },
    onInput(value) {
      this.$emit('input', value);
    },
    async isValid() {
      await this.$validator.validate();

      this.validateDates();

      return this.veeErrors.items.length === 0;
    },
    validateDates() {
      const startDate = this.startDate ? parseISO(this.startDate) : null;
      const endDate = this.endDate ? parseISO(this.endDate) : null;
      const drawDate = this.drawDate ? parseISO(this.drawDate) : null;

      // Find min End Date
      if (!startDate) {
        this.minEndDate = new Date();
      } else if (startDate && endDate) {
        this.minEndDate = startDate;
      } else if (startDate) {
        this.minEndDate = startDate;
      }

      // Find min Draw Date
      if (!endDate && !startDate) {
        this.minDrawDate = new Date();
      } else if (endDate && drawDate) {
        this.minDrawDate = endDate;
      } else if (endDate) {
        this.minDrawDate = endDate;
      } else if (startDate) {
        this.minDrawDate = startDate;
      }

      const timeZone = getTimezone(this.province);

      if (!timeZone) {
        this.veeFields['input-end-date']['dirty'] = true;
        this.veeFields['input-end-date']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-end-time',
          msg: 'Missing Time Zone'
        });
      }

      this.startDateTime = this.startDate ? zonedTimeToUtc(`${this.startDate} ${this.startTime}`, timeZone) : null;
      this.endDateTime = this.endDate ? zonedTimeToUtc(`${this.endDate} ${this.endTime}`, timeZone) : null;
      this.drawDateTime = this.drawDate ? zonedTimeToUtc(`${this.drawDate} ${this.drawTime}`, timeZone) : null;

      // validate end date
      if (parseISO(this.startDate) > parseISO(this.endDate)) {
        this.veeFields['input-end-date']['dirty'] = true;
        this.veeFields['input-end-date']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-end-date',
          msg: 'The Ticket Sales End Date cannot be earlier than the Ticket Sales Start Date'
        });
      } else if (endDate && this.startDateTime >= this.endDateTime) {
        this.veeFields['input-end-time']['dirty'] = true;
        this.veeFields['input-end-time']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-end-time',
          msg: 'The Ticket Sales End Time cannot be earlier than the Ticket Sales Start Time'
        });
      }

      // validate draw date
      if (parseISO(this.endDate) > parseISO(this.drawDate)) {
        this.veeFields['input-draw-date']['dirty'] = true;
        this.veeFields['input-draw-date']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-draw-date',
          msg: 'The Draw Date cannot be earlier than the Ticket Sales End Date'
        });
      } else if (this.drawDate && this.endDateTime > this.drawDateTime) {
        this.veeFields['input-draw-time']['dirty'] = true;
        this.veeFields['input-draw-time']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-draw-time',
          msg: 'The Draw Time cannot be earlier than the Ticket Sales End Time'
        });
      }
    }
  }
};
</script>
