<template>
  <div>
    <b-form-group label-for="input-draw-location" :invalid-feedback="veeErrors.first('input-draw-location')">
      <template slot="label">Draw Location <span class="text-danger">*</span></template>
      <b-form-input
        name="input-draw-location"
        v-bind:value="value"
        v-on:input="onInput"
        v-validate="'required'"
        :state="validateState()"
        aria-describedby="input-draw-location-feedback"
        data-vv-as="Draw Location"
        trim
        required
      />
    </b-form-group>
  </div>
</template>
<script>
import trumbowygConfig from '@/trumbowygConfig';

const name = 'input-draw-location';

export default {
  props: ['value'],
  data() {
    return {
      config: trumbowygConfig.getConfig()
    };
  },
  methods: {
    validateState() {
      if (this.veeFields[name] && (this.veeFields[name].dirty || this.veeFields[name].validated)) {
        return !this.veeErrors.has(name);
      }

      return null;
    },
    isValid() {
      this.$validator.validate();
      return this.veeFields[name]['invalid'] === false;
    },
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
