<template>
  <div>
    <b-form-group
      label="Additional Comments"
      label-for="input-comments"
      :invalid-feedback="veeErrors.first('input-event-comments')"
      class="text-md"
    >
      <b-form-textarea
        name="input-event-comments"
        v-bind:value="value"
        v-on:input="onInput"
        aria-describedby="input-event-comments-feedback"
        data-vv-as="Additional Comments"
        class="form-control mt-2"
        placeholder="Enter comments..."
        rows="3"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
