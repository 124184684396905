<template>
  <b-container class="mb-7">
    <h1>Raffles</h1>
    <b-alert v-model="success.model" variant="success" dismissible fade>
      {{ success.text }}
    </b-alert>
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>

    <div class="nav-container">
      <b-form-input v-model="form.input" class="nav-container__item search" placeholder="Enter search" debounce="300" />
      <CreateRaffleModalV2 class="nav-container__item" v-if="showCreateRaffle" />
      <CopyRaffleModalV2 v-if="showCopyRaffle" class="nav-container__item" />
      <RequestNewRaffleBuildModal class="nav-container__item neg-mb" v-if="showRequestNewRaffle">
        Start a
      </RequestNewRaffleBuildModal>
      <LoadingButton class="nav-container__item" variant="outline" @onClick="toggleView()">
        <font-awesome-icon v-if="this.tableView" class="mr-2" :icon="['fas', 'grid']" />
        <font-awesome-icon v-else class="mr-2" :icon="['fas', 'table-list']" />
        {{ this.tableView ? 'View Cards' : 'View Table' }}
      </LoadingButton>
      <GenericSelectBox
        id="PageSize"
        class="nav-container__item nav-container__item--select"
        :options="[
          { name: '12 Per Page', value: 12 },
          { name: '24 Per Page', value: 24 },
          { name: '36 Per Page', value: 36 },
          { name: '48 Per Page', value: 48 }
        ]"
        @change="onPageSizeChange"
        :value="String(pagination.pageSize)"
      />
    </div>

    <div class="max-width" v-if="tableView">
      <GenericTable
        :columns="columns"
        :data="events"
        :pagination="pagination"
        :loading="loading"
        @sort="sort"
        @updateCurrentPage="updateCurrentPage"
        class="raffles"
      >
        <template #id="{ row }">
          {{ row.number }}
        </template>
        <template #name="{ row }">
          <router-link :to="{ path: `raffle?id=` + row.id }" class="text-gray-800 underline">
            {{ row.name }}
          </router-link>
        </template>
        <template #endDate="{ row }">
          {{ formatDateTime(parseISO(row.endDate)) }}
        </template>
        <template #jackpot="{ row }">
          {{ formatCurrency(row.jackpot) }}
        </template>
        <template #status="{ row }">
          <EventStatusBadge :status="row.status" />
        </template>
        <template #shortlink="{ row }">
          <a :href="raffleboxUrl + '/raffle/' + row.shortlink" target="top" class="text-gray-800 underline">
            {{ row.shortlink }}
          </a>
        </template>
      </GenericTable>
    </div>

    <!-- Card View -->
    <div v-else>
      <div class="m-auto mb-4 mt-10" v-if="loading || pagination.total === 0">
        <div v-if="loading">
          <LoadingSpinner class="m-auto" />
        </div>
        <div class="p-4" v-if="!loading && pagination.total === 0">
          <div class="flex items-center justify-center m-auto">
            <p class="py-3">{{ emptyMessage || 'No items added' }}</p>
          </div>
        </div>
      </div>
      <b-card-group>
        <b-link :href="'/raffle?id=' + event.id" v-for="event in events" :key="event.id" class="raffle-card__link">
          <b-card :img-src="imageUrl(event.logoUrl)" :img-alt="event.name" class="raffle-card" no-body>
            <b-card-body class="flex flex-column justify-between">
              <div>
                <p class="raffle-name">
                  <strong>{{ event.name }}</strong>
                </p>
                <p class="mb-2 jackpot-dollars">
                  <strong v-if="event.status !== 'pending'">{{ formatCurrency(event.jackpot) }} raised</strong>
                </p>
                <p class="mb-2" v-if="event.licenseNumber"><strong>License:</strong> {{ event.licenseNumber }}</p>
              </div>
              <div>
                <div class="mt-auto" v-if="event.status === 'active'">
                  <p class="mb-2 event-status">Ticket sales end in {{ formatDistance(parseISO(event.endDate)) }}</p>
                  <EventStatusBadge status="active" />
                </div>
                <div v-if="event.status === 'ended'">
                  <p class="mb-2 event-status">Ticket sales ended {{ formatDateTime(parseISO(event.endDate)) }}</p>
                  <EventStatusBadge status="ended" />
                </div>
                <div class="mt-auto" v-if="event.status === 'pending'">
                  <EventStatusBadge status="pending" />
                </div>
                <div class="mt-auto" v-if="event.status === 'closed'">
                  <p class="mb-2 event-status">Ticket sales ended {{ formatDateTime(parseISO(event.endDate)) }}</p>
                  <EventStatusBadge status="closed" />
                </div>
              </div>
            </b-card-body>
            <b-card-footer
              :class="footerType(event.status)"
              v-if="
                (event.status === 'ended' && event.winningTicket && !event.winningTicket.number) ||
                event.status === 'pending'
              "
            >
              <div v-if="event.status === 'ended' && !event.winningTicket.number">
                <small> <b-icon-exclamation-triangle /> Raffle has ended, please select the winner </small>
              </div>
              <div v-if="event.status === 'pending'">
                <small> <b-icon-pencil-square /> Edit and activate the raffle to sell tickets </small>
              </div>
            </b-card-footer>
          </b-card>
        </b-link>
      </b-card-group>
    </div>
    <div class="mb-2" v-if="pagination && !tableView">
      Total Rows: <b> {{ pagination.total }}</b>
    </div>
    <Pagination
      v-if="pagination && !tableView"
      :pages="pagination.totalPages"
      :currentPage="currentPage"
      @updateCurrentPage="updateCurrentPage"
      class="mt-10"
    />
  </b-container>
</template>

<script>
import EventServiceV2 from '@/lib/event-service-v2';
import config from '@/config';
import CreateRaffleModalV2 from '@/components/modals/CreateRaffleModalV2';
import CopyRaffleModalV2 from '@/components/modals/CopyRaffleModalV2';
import EventStatusBadge from '@/components/EventStatusBadge';
import LoadingButton from '@/components/ui/LoadingButton';
import GenericSelectBox from '@/components/inputs/GenericSelectBox.vue';
import RequestNewRaffleBuildModal from '@/components/modals/RequestNewRaffleBuildModal';
import GenericTable from '@/components/GenericTable';
import Pagination from '@/components/ui/Pagination';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import { setCookie, getCookie } from '@/lib/cookies';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';

export default {
  name: 'Raffles',
  components: {
    CreateRaffleModalV2,
    CopyRaffleModalV2,
    EventStatusBadge,
    LoadingButton,
    GenericSelectBox,
    Pagination,
    RequestNewRaffleBuildModal,
    GenericTable,
    LoadingSpinner
  },
  watch: {
    'form.input': function () {
      this.updateCurrentPage(0);
      this.refreshEvents();
    }
  },
  data() {
    return {
      events: [],
      tableView: false,
      raffleboxUrl: config.RAFFLEBOX_URL,
      alert: {
        text: '',
        model: false
      },
      success: {
        text: '',
        model: false
      },
      form: {
        input: ''
      },
      columns: [
        {
          name: 'id',
          label: 'Number',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'endDate',
          label: 'End Date',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'name',
          label: 'Name',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'status',
          label: 'Status',
          classes: 'w-auto'
        },
        {
          name: 'shortlink',
          label: 'Shortlink',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'licenseNumber',
          label: 'License Number',
          classes: 'w-auto'
        },
        {
          name: 'jackpot',
          label: 'Jackpot',
          classes: 'w-auto'
        }
      ],
      pagination: {
        total: 0,
        totalPages: 0,
        pageSize: 12,
        sortBy: 'id',
        sortDir: 'desc'
      },
      currentPage: 0,
      loading: false,
      viewRequestARaffle: false,
      canCreateRaffle: false,
      canCopyRaffle: false,
      emptyMessage: ''
    };
  },
  async mounted() {
    this.loadEvents();

    this.viewRequestARaffle = await unleashFeatureEnabled(UnleashKeys.StartARafflebox);
    this.canCreateRaffle = await unleashFeatureEnabled(UnleashKeys.CreateRaffle);
    this.canCopyRaffle = await unleashFeatureEnabled(UnleashKeys.CopyRaffle);
  },
  created() {
    const viewRaffles = getCookie('viewRaffle');

    if (viewRaffles === 'View Cards') {
      this.tableView = false;
    } else if (viewRaffles === 'View Table') {
      this.tableView = true;
    } else {
      this.tableView = false;
      setCookie('viewRaffle', 'View Cards', 30);
    }
  },
  computed: {
    showCreateRaffle() {
      return this.canCreateRaffle;
    },
    showCopyRaffle() {
      return this.canCopyRaffle;
    },
    showRequestNewRaffle() {
      return this.viewRequestARaffle;
    }
  },
  methods: {
    imageUrl(imageUrl) {
      if (imageUrl) {
        return imageUrl;
      }

      // Get image asset using Webpack require.context
      return require.context('@/assets', true)('./no-image.png');
    },
    footerType(item) {
      let className = '';

      if (item) {
        if (item === 'pending') {
          className = 'card-footer--pending';
        } else if (item === 'ended') {
          className = 'card-footer--ended';
        }
      }

      return className;
    },
    async sort(sortBy, sortDir) {
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;
      await this.refreshEvents();
    },
    updateCurrentPage(page) {
      this.currentPage = page;
      this.$nextTick(() => {
        this.refreshEvents();
      });
    },
    onPageSizeChange(pageSize) {
      this.pagination.pageSize = Number(pageSize);
      this.$nextTick(() => {
        this.refreshEvents();
      });
    },
    toggleView() {
      this.tableView = !this.tableView;
      setCookie('viewRaffle', this.tableView ? 'View Table' : 'View Cards', 30);
    },
    async refreshEvents() {
      await this.loadEvents();
    },
    async handleSuccess(text) {
      this.success.text = text;
      this.success.model = true;
      if (this.tableView) {
        await this.refreshEvents();
      }
    },
    handleError(error) {
      this.alert.text = error.response ? error.response.data.errors[0].message : error;
      this.alert.model = true;
    },
    getTotalPages(pagination) {
      if (pagination) {
        return Math.ceil(parseInt(pagination.total, 10) / parseInt(pagination.pageSize, 10)) || 1;
      } else {
        return 0;
      }
    },
    async loadEvents() {
      const params = {
        page: this.currentPage,
        pageSize: this.pagination.pageSize,
        sortBy: this.pagination.sortBy || undefined,
        sortDir: this.pagination.sortDir,
        search: this.form.input ? this.form.input : undefined
      };

      try {
        this.loading = true;
        const result = await EventServiceV2.listEvents(params);

        this.pagination = result.pagination;
        this.pagination.totalPages = this.getTotalPages(this.pagination);
        this.events = result.data;
        this.loading = false;
      } catch (error) {
        this.handleError(error);
        this.events = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;

  &__item {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    height: 45px;
  }

  .card,
  .card-body {
    height: 100%;
  }

  .search {
    width: calc(100% - 50px);
    border-color: #6c757d;
  }

  @media screen and (min-width: 62em) {
    .search {
      max-width: 30rem;
    }
  }

  @media screen and (min-width: 85em) {
    max-width: 1460px;

    &__item--select {
      margin-left: auto;
    }
  }
}

.raffle-card {
  width: 100%;
  height: 100%;

  &__link {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;

    @media screen and (min-width: 40em) {
      width: calc(50% - 0.5rem);

      &:nth-child(odd) {
        margin-right: 1rem;
      }
    }

    @media screen and (min-width: 62em) {
      width: calc(33.333% - 1rem);
      margin-right: 1rem;

      &:nth-child(3n) {
        margin-right: 0rem;
      }
    }

    @media screen and (min-width: 75em) {
      width: 22rem;

      &:nth-child(3n) {
        margin-right: 1rem;
      }
    }
  }
}

.raffle-card .rb-alert__icon {
  left: -1.5rem;
}

.raffle-card .rb-alert__icon svg {
  font-size: 250% !important;
}

.rb-link {
  border: 0px none;
  color: #000000;
}

.rb-link-icon {
  min-width: 2.5rem;
  color: #000000;
}

.raffle-card .rb-alert {
  margin: 1rem 0 -1rem 0.5rem;
  padding: 0.8rem 0.8rem 0.8rem 2rem;
}

.raffle-name {
  font-size: 1em;
}

.jackpot-dollars {
  font-size: 1.25em;
  font-weight: bold;
}

.event-status {
  font-size: 0.75rem;
  font-weight: 800;
  color: rgb(125, 125, 125);
}

a {
  text-decoration: none;
  color: black;
}
</style>

<style>
.card-group {
  max-width: 1480px;
}

.raffle-card .card-img {
  min-width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.raffle-card .card-footer {
  background-color: transparent;
  border-top-width: 2px;
  text-align: center;
}

.raffle-card .card-footer--pending {
  border-top-color: #6c757d;
}

.raffle-card .card-footer--ended {
  border-top-color: #8075ff;
}
.max-width {
  max-width: 1460px;
}
</style>
