<template>
  <div>
    <label v-if="label" :for="id">{{ label }}</label>
    <select
      class="
        block
        relative
        w-full
        min-h-full
        p-2.5
        pr-5
        rounded-lg
        border border-gray-600
        appearance-none
        bg-select-image bg-white bg-no-repeat bg-right-center bg-select-icon-size
        outline-none
        hover:bg-gray-600
        hover:text-white
        cursor-pointer
      "
      :id="id"
      @change="$emit('change', $event.target.value)"
      :value="value"
      :disabled="disabled"
    >
      <option v-if="defaultSelection" value="" selected>{{ defaultSelection }}</option>
      <option v-for="option in options" :key="option.value" :value="option.value">{{ option.name }}</option>
    </select>
  </div>
</template>

<script>
import triangle from '@/assets/svgs/triangle.svg';
export default {
  name: 'GenericSelectBox',
  props: {
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    value: {
      type: String
    },
    label: {
      type: String
    },
    defaultSelection: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      triangle
    };
  }
};
</script>
