<template>
  <div>
    <b-button v-b-modal.copy-raffle-modal variant="outline-secondary">
      <i class="fa-solid fa-copy pr-1"></i>
      Copy
    </b-button>

    <b-modal
      id="copy-raffle-modal"
      title="Copy a Raffle"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      size="lg"
    >
      <b-card no-body>
        <b-tabs card>
          <b-form @submit.stop.prevent="onSubmit">
            <b-tab title="Details">
              <div v-if="copyRaffleFailed" class="alert alert-danger">Failed to copy raffle: {{ errorMessage }}</div>

              <b-form-group label="Copy Raffle" label-for="input-raffle-name">
                <EventSelectV2 @eventSelected="onEventSelected" />
              </b-form-group>

              <b-form-group
                label="Raffle Name"
                label-for="input-raffle-name"
                :invalid-feedback="veeErrors.first('input-raffle-name')"
              >
                <b-form-input
                  name="input-raffle-name"
                  v-model="event.name"
                  v-validate="{ required: true, min: 3 }"
                  :state="validateState('input-raffle-name')"
                  aria-describedby="input-raffle-name-feedback"
                  data-vv-as="raffle name"
                  trim
                />
              </b-form-group>

              <EventShortlinkInput ref="eventShortlinkInput" v-model="event.shortlink" />
              <EventTimeInput ref="eventTimeInput" :province="event.province" />
              <StartingTicketNumberInput ref="startingTicketNumberInput" v-model="event.startingTicketNumber" />

              <b-form-group label-for="input-copy-prizes" :invalid-feedback="veeErrors.first('input-copy-prizes')">
                <b-form-checkbox v-model="copyPrizes" name="input-copy-prizes" switch>
                  <span>Copy Prizes</span>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group label-for="input-copy-license" :invalid-feedback="veeErrors.first('input-copy-license')">
                <b-form-checkbox v-model="copyLicense" name="input-copy-license" switch>
                  <span>Copy License</span>
                </b-form-checkbox>
              </b-form-group>
            </b-tab>
          </b-form>
        </b-tabs>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import EventServiceV2 from '@/lib/event-service-v2';

import EventSelectV2 from '@/components/EventSelectV2';
import EventShortlinkInput from '@/components/forms/EventShortlinkInput';
import EventTimeInput from '@/components/forms/EventTimeInput';
import StartingTicketNumberInput from '@/components/forms/StartingTicketNumberInput';

export default {
  components: { EventSelectV2, EventShortlinkInput, EventTimeInput, StartingTicketNumberInput },
  data() {
    return {
      modalShow: false,
      copyRaffleFailed: false,
      errorMessage: null,
      event: {},
      copyPrizes: true,
      copyLicense: false
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetForm() {
      this.modalShow = false;
      this.copyRaffleFailed = false;
      this.errorMessage = null;
      this.copyPrizes = true;
      this.copyLicense = false;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    onEventSelected(event) {
      this.event = event;
    },

    async onSubmit() {
      this.event.startDate = this.$refs.eventTimeInput.startDateTime;
      this.event.endDate = this.$refs.eventTimeInput.endDateTime;
      this.event.drawDate = this.$refs.eventTimeInput.drawDateTime;

      const formValid = await this.$validator.validateAll();
      const datesValid = this.$refs.eventTimeInput.isValid();
      const shortlinkValid = this.$refs.eventShortlinkInput.isValid();

      if (!formValid || !datesValid || !shortlinkValid) {
        return;
      }

      try {
        const copyRaffle = {
          shortlink: this.event.shortlink,
          name: this.event.name,
          startDate: this.event.startDate,
          endDate: this.event.endDate,
          drawDate: this.event.drawDate,
          startTicketNum: this.event.startingTicketNumber,
          copyPrizes: this.copyPrizes,
          copyLicense: this.copyLicense
        };

        await EventServiceV2.copyRaffle(this.event.id, copyRaffle);

        this.modalShow = false;
        this.$router.go();
      } catch (error) {
        this.copyRaffleFailed = true;
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    }
  }
};
</script>

<style scoped></style>
